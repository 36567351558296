import { Pole } from '@/models/pole';
import { GET_POLES } from './constants';
import { PoleState } from './types';

const removeDuplicates = (array: Pole[]) => {
  return array.filter(
    (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
  );
};

const getters = {
  [GET_POLES](state: PoleState) {
    return removeDuplicates(state.poles);
  },
};

export default getters;
