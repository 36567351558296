import { Client } from '@/models/client';
import { GET_CLIENTS } from './constants';
import { ClientState } from './types';

const removeDuplicates = (array: Client[]) => {
  return array.filter(
    (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
  );
};

const getters = {
  [GET_CLIENTS](state: ClientState) {
    return removeDuplicates(state.clients);
  },
};

export default getters;
